import React, { useState, useEffect } from 'react';
import './styles/BillingSettings.css';

function BillingSettings({ user_id }) {
  const [billingInfo, setBillingInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cancelLoading, setCancelLoading] = useState(false);

  useEffect(() => {
    const fetchBillingInfo = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://junbispark.net/get_billing_info/?user_id=${user_id}`);
        const data = await response.json();

        if (data.status === 'success') {
          setBillingInfo(data.billing);
        } 
        
        else {
          console.error('Error retrieving billing info:', data.message);
          setBillingInfo(null);
        }
      } 
      
      catch (error) {
        console.error('Network or server error fetching billing info:', error);
        setBillingInfo(null);
      }
      setLoading(false);
    };

    fetchBillingInfo();
  }, [user_id]);

  const handleCancelSubscription = async () => {
    if (!window.confirm("Are you sure you want to cancel your subscription?")) return;
    setCancelLoading(true);
    try {
      const response = await fetch(`https://junbispark.net/subscription_cancellation/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id })
      });
      const result = await response.json();
      if (result.status === 'success') {
        alert("Subscription cancelled successfully.");
        setBillingInfo(prev => {
          if (!prev) return prev;
          return {
            ...prev,
            subscription: {
              ...prev.subscription,
              status: result.subscription?.status || 'canceled'
            }
          };
        });
      } else {
        alert("Failed to cancel subscription.");
      }
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      alert("Error cancelling subscription.");
    }
    setCancelLoading(false);
  };

  const handleUpdatePaymentMethod = async () => {
    try {
      // You might have logic to detect plan or account type from your user data
      const plan = billingInfo?.subscription?.plan_id || 'standard';
      const accountType = 'couple'; // or 'business', depending on your user data

      const response = await fetch(
        `https://junbispark.net/stripe_session?user_id=${user_id}&plan=${plan}&account_type=${accountType}`
      );
      const data = await response.json();
      if (data.status === 'success') {
        // Redirect to the Stripe Checkout page
        window.location.href = data.checkoutUrl;
      } else {
        alert("Error updating payment method.");
      }
    } catch (error) {
      console.error("Error updating payment method:", error);
      alert("Error updating payment method.");
    }
  };

  if (loading) {
    return <div className="billing-settings">Loading billing information...</div>;
  }

  if (!billingInfo) {
    return (
      <div className="billing-settings">
        <h2>Billing Settings</h2>
        <p>No billing information available or an error occurred.</p>
      </div>
    );
  }

  const { subscription, payment_method, upcoming_invoice, invoices } = billingInfo;

  if (!subscription) {
    return (
      <div className="billing-settings">
        <h2>Billing Settings</h2>
        <p>You have no active subscription. Please subscribe below.</p>
        <button onClick={handleUpdatePaymentMethod}>Subscribe</button>
      </div>
    );
  }

  const nextBillingDate = upcoming_invoice?.next_payment_attempt
    ? new Date(upcoming_invoice.next_payment_attempt * 1000).toLocaleDateString()
    : subscription.end_date
      ? new Date(subscription.end_date * 1000).toLocaleDateString()
      : 'N/A';

  return (
    <div className="billing-settings">
      <h2>Billing Settings</h2>
      <div className="billing-item">
        <label>Subscription Status:</label>
        <p>{subscription.status || 'Not subscribed'}</p>
      </div>
      <div className="billing-item">
        <label>Plan:</label>
        <p>{subscription.plan_id || 'N/A'}</p>
      </div>
      <div className="billing-item">
        <label>Payment Method:</label>
        {payment_method ? (
          <p>
            {payment_method.brand.toUpperCase()} **** **** **** {payment_method.last4}<br />
            Expires {payment_method.exp_month}/{payment_method.exp_year}
          </p>
        ) : (
          <p>No payment method on file</p>
        )}
      </div>
      <div className="billing-item">
        <label>Next Billing Date:</label>
        <p>{nextBillingDate}</p>
      </div>
      <div className="billing-item">
        <label>Current Period End:</label>
        <p>
          {subscription.end_date
            ? new Date(subscription.end_date * 1000).toLocaleDateString()
            : 'N/A'}
        </p>
      </div>

      {/* Display a short invoice history */}
      {invoices && invoices.length > 0 && (
        <div className="billing-item invoice-history">
          <h3>Past Invoices</h3>
          <table>
            <thead>
              <tr>
                <th>Invoice ID</th>
                <th>Amount Paid</th>
                <th>Status</th>
                <th>Period Start</th>
                <th>Period End</th>
                <th>Invoice PDF</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map(inv => (
                <tr key={inv.invoice_id}>
                  <td>{inv.invoice_id}</td>
                  <td>${(inv.amount_paid / 100).toFixed(2)}</td>
                  <td>{inv.status}</td>
                  <td>
                    {inv.period_start
                      ? new Date(inv.period_start * 1000).toLocaleDateString()
                      : 'N/A'}
                  </td>
                  <td>
                    {inv.period_end
                      ? new Date(inv.period_end * 1000).toLocaleDateString()
                      : 'N/A'}
                  </td>
                  <td>
                    {inv.invoice_pdf ? (
                      <a href={inv.invoice_pdf} target="_blank" rel="noopener noreferrer">
                        Download
                      </a>
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="billing-actions">
        {subscription.status === 'active' || subscription.status === 'trialing' ? (
          <button onClick={handleCancelSubscription} disabled={cancelLoading}>
            {cancelLoading ? 'Cancelling...' : 'Cancel Subscription'}
          </button>
        ) : null}
        <button onClick={handleUpdatePaymentMethod}>Update Payment Method</button>
      </div>
    </div>
  );
}

export default BillingSettings;
