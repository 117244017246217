import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import { venueDetailsMapping } from '../misc/venueDetailsMapping';
import 'leaflet/dist/leaflet.css';
import '../styles/Distance.css';

import markerRed from '../assets/map-marker-red.png';
import markerBlack from '../assets/map-marker-black.png';
import DangerIcon from '../assets/modal-icon-danger.png';

const pinkIcon = new L.Icon({
  iconUrl: markerRed,
  iconSize: [25, 25],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  shadowSize: [41, 41],
});

const greyIcon = new L.Icon({
  iconUrl: markerBlack,
  iconSize: [25, 25],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  shadowSize: [41, 41],
});

// Haversine function to calculate distance (in miles) between two coordinate pairs
const haversineDistance = (coords1, coords2) => {
  const toRad = (x) => (x * Math.PI) / 180;
  const lat1 = coords1.latitude;
  const lon1 = coords1.longitude;
  const lat2 = coords2.latitude;
  const lon2 = coords2.longitude;
  const R = 3958.8; // Earth's radius in miles
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

const Distance = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user_id } = useParams();
  // Retrieve the initially selected venue names from previous page (if any)
  const initialSelectedVenues = location.state?.selectedVenues || [];

  // State for which venues are highlighted (i.e. selected)
  const [highlightedVenues, setHighlightedVenues] = useState(initialSelectedVenues);
  // State for the user's current location (if available)
  const [currentLocation, setCurrentLocation] = useState(null);
  // State for the selected distance filter (in miles); empty string means “All”
  const [selectedDistance, setSelectedDistance] = useState('');
  // State for custom location input (a user-typed location)
  const [customLocationInput, setCustomLocationInput] = useState('');
  const [mapHeight, setMapHeight] = useState('700px');

  // States for account status and modal alerts
  const [accountStatus, setAccountStatus] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMapHeight('500px');
      } else {
        setMapHeight('700px');
      }
    };
    // Call it initially
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch account status from API when component mounts.
  useEffect(() => {
    const url = `https://junbispark.net/account_status/?user_id=${user_id}`;
    console.log("Fetching account status from URL:", url);
    fetch(url, { credentials: 'include' })
      .then((response) => {
        console.log("Response received:", response);
        return response.json();
      })
      .then((data) => {
        console.log("Data received:", data);
        if (data.status === 'success') {
          setAccountStatus(data.account_status);
          console.log("Account status set:", data.account_status);
        } else {
          console.error("Error fetching account status:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
      });
  }, [user_id]);

  // On component mount, try to obtain the user's current location (if permitted)
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }, []);

  // Determine max allowed venues based on subscription plan
  const getMaxAllowedVenues = () => {
    if (accountStatus) {
      const plan = accountStatus.subscription.plan;
      if (plan === 'trial' || plan === 'essentials') {
        return 1;
      }
    }
    return Infinity; // For standard or premium
  };

  // Toggle the highlighted state for a venue when its marker is clicked
  const handleMarkerClick = (venueName) => {
    if (!accountStatus) {
      alert('Loading account status, please wait.');
      return;
    }
    const maxVenues = getMaxAllowedVenues();
    // If the venue is already selected, allow deselection
    if (highlightedVenues.includes(venueName)) {
      setHighlightedVenues(highlightedVenues.filter((name) => name !== venueName));
      return;
    }
    // If selection limit is reached, show modal and do not add
    if (highlightedVenues.length >= maxVenues) {
      console.warn("Selection limit reached. Cannot select more venues.");
      setModalMessage(
        "Due to your current plan, you can only select one venue. Upgrade to view more."
      );
      setShowModal(true);
      return;
    }
    setHighlightedVenues([...highlightedVenues, venueName]);
  };

  // Convert the venueDetailsMapping object into an array of venues.
  // Each venue is expected to have: latitude, longitude, title, caption.
  const allVenues = Object.keys(venueDetailsMapping).map((key) => ({
    name: key,
    ...venueDetailsMapping[key],
  }));

  // Optionally filter venues based on the selected distance and the user's current location
  const filteredVenues =
    selectedDistance && currentLocation
      ? allVenues.filter((venue) => {
          if (!venue.latitude || !venue.longitude) return false;
          const distance = haversineDistance(currentLocation, {
            latitude: venue.latitude,
            longitude: venue.longitude,
          });
          return distance <= Number(selectedDistance);
        })
      : allVenues;

  // Determine the center of the map:
  // Prefer the user's current location, else fallback to the first venue with coordinates or [0, 0]
  const mapCenter =
    currentLocation ||
    (allVenues.length > 0 &&
      allVenues[0].latitude &&
      allVenues[0].longitude &&
      [allVenues[0].latitude, allVenues[0].longitude]) ||
    [0, 0];

  // Handler for custom location submission: geocode the entered location
  const handleCustomLocationSubmit = async (e) => {
    e.preventDefault();
    if (!customLocationInput) return;
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          customLocationInput
        )}`
      );
      const data = await response.json();
      if (data && data.length > 0) {
        const { lat, lon } = data[0];
        setCurrentLocation({
          latitude: parseFloat(lat),
          longitude: parseFloat(lon),
        });
      } else {
        alert('Location not found.');
      }
    } catch (err) {
      console.error(err);
      alert('Error fetching location.');
    }
  };

  // Handler for navigating to the next step
  const handleNextStep = () => {
    navigate(`/preferences/${user_id}`, { state: { selectedVenues: highlightedVenues } });
  };

  return (
    <div className="main-distance-page-container">
      <header className="logo">
        <a href="/">BestDay2Marry</a>
      </header>
      <div className="distance-page-container">
        <div className="logo-spacer"></div>

        {/* Distance selector */}
        <div className="distance-controls">
          <label htmlFor="distance-select">
            Select a distance (miles) from your current location (optional):
          </label>
          <select
            id="distance-select"
            value={selectedDistance}
            onChange={(e) => setSelectedDistance(e.target.value)}
          >
            <option value="">All</option>
            <option value="5">5 miles</option>
            <option value="10">10 miles</option>
            <option value="20">20 miles</option>
            <option value="50">50 miles</option>
          </select>
        </div>

        {/* Custom location search bar */}
        <div className="custom-location-control">
          <form onSubmit={handleCustomLocationSubmit}>
            <label htmlFor="custom-location">Or enter a location:</label>
            <input
              type="text"
              id="custom-location"
              value={customLocationInput}
              onChange={(e) => setCustomLocationInput(e.target.value)}
              placeholder="Enter city, address, etc."
            />
            <button type="submit">Set Location</button>
          </form>
        </div>

        {/* Map */}
        <div className="map-container">
          <MapContainer center={mapCenter} zoom={12} style={{ height: mapHeight, width: '100%' }}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {filteredVenues.map((venue) => {
              if (!venue.latitude || !venue.longitude) return null;
              const isHighlighted = highlightedVenues.includes(venue.name);
              return (
                <Marker
                  key={venue.name}
                  position={[venue.latitude, venue.longitude]}
                  icon={isHighlighted ? pinkIcon : greyIcon}
                  eventHandlers={{
                    click: () => handleMarkerClick(venue.name),
                  }}
                >
                  <Tooltip direction="top" offset={[0, -20]} opacity={1}>
                    <div className="tooltip-content">
                      <strong>{venue.title}</strong>
                      <p>{venue.caption}</p>
                    </div>
                  </Tooltip>
                </Marker>
              );
            })}
          </MapContainer>
        </div>

        {/* Selected Venues Bucket */}
        <div className="selected-venues-bucket">
          <h3>Selected Venues</h3>
          {highlightedVenues.length === 0 && <p>No venues selected.</p>}
          <div className="selected-venues-list">
            {highlightedVenues.map((venueName) => {
              const details = venueDetailsMapping[venueName];
              if (!details) return null;
              return (
                <div className="selected-venue-item" key={venueName}>
                  <img src={details.imageUrl} alt={details.title} className="selected-venue-image" />
                  <p>{details.title}</p>
                </div>
              );
            })}
          </div>
        </div>

        {/* Next Step Button */}
        <div className="next-step-container">
          <button className="next-step-button" onClick={handleNextStep}>
            Next Step
          </button>
        </div>
      </div>

      {/* Modal for permission errors */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <img src={DangerIcon} alt="Danger Icon" className="danger-icon" />
            <p>{modalMessage}</p>
            <button onClick={() => setShowModal(false)} className="modal-button">
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Distance;
