import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/MatchingFlow.css';


/*

Need to create pop up modals  for handling error messages
gracefully, alerting users.


*/

function MatchingFlow() {
  const navigate = useNavigate();
  const { user_id } = useParams();
  const [selectedPlan, setSelectedPlan] = useState('trial'); // Default plan

  const handlePlanChange = (e) => {
    setSelectedPlan(e.target.value);
  };

  const handleCheckout = async () => {
    try {
      const response = await fetch(`https://junbispark.net/stripe_session?user_id=${user_id}&plan=${selectedPlan}&account_type=couple`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ plan: selectedPlan })
      });

      const sessionData = await response.json();

      if (sessionData.status === 'success' && sessionData.checkoutUrl) {
        window.location.href = sessionData.checkoutUrl;
      } 
      
      else {
        console.error("Failed to create a Stripe checkout session.");
      }
    } 
    
    catch (error) {
      console.error("Error creating Stripe session:", error);
    }
  };

  return (
    <div className="matching-flow-container">
      <header className="logo">
        <a href="/">BestDay2Marry</a>
      </header>
      <main>
        <h1>Wedding Matching Process</h1>
        <p>Select your subscription plan to get started:</p>
        <form>
          <fieldset>
            <legend className="visually-hidden">Subscription Plans</legend>
            <div className="plan-option">
              <label>
                <input
                  type="radio"
                  name="plan"
                  value="trial"
                  checked={selectedPlan === 'trial'}
                  onChange={handlePlanChange}
                />
                <span className="plan-title">Trial (Free)</span>
              </label>
              <p className="plan-details">1 week access, 1 location, 1 date.</p>
            </div>
            <div className="plan-option">
              <label>
                <input
                  type="radio"
                  name="plan"
                  value="essentials"
                  checked={selectedPlan === 'essentials'}
                  onChange={handlePlanChange}
                />
                <span className="plan-title">Essentials ($10 per use)</span>
              </label>
              <p className="plan-details">1 location, any date.</p>
            </div>
            <div className="plan-option">
              <label>
                <input
                  type="radio"
                  name="plan"
                  value="standard"
                  checked={selectedPlan === 'standard'}
                  onChange={handlePlanChange}
                />
                <span className="plan-title">Standard ($50 monthly)</span>
              </label>
              <p className="plan-details">Any location, any date.</p>
            </div>
            <div className="plan-option">
              <label>
                <input
                  type="radio"
                  name="plan"
                  value="premium"
                  checked={selectedPlan === 'premium'}
                  onChange={handlePlanChange}
                />
                <span className="plan-title">Premium ($520 yearly)</span>
              </label>
              <p className="plan-details">Any location, any date.</p>
            </div>
          </fieldset>
          <button type="button" className="big-button" onClick={handleCheckout}>
            Proceed to Payment
          </button>
          <button type="button" className="small-button" onClick={() => navigate('/signin')}>
            Cancel
          </button>
        </form>
      </main>
    </div>
  );
}

export default MatchingFlow;
