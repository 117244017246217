import React, { useState, useEffect } from 'react';
import { 
  fetchVenueForUser, 
  fetchUnitSettingsForUser, 
  getUserAccountType, 
  saveUnitsToBackend 
} from '../utils/queries';
import './styles/GeneralSettings.css';

function GeneralSettings({ user_id }) {
  const [venue, setVenue] = useState(null);
  const [units, setUnits] = useState({
    t2m_units: 'F',
    precipitation_units: 'in',
    ws2m_units: 'mph'
  });
  const [accountType, setAccountType] = useState('business');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch the current venue for the user
      const userVenue = await fetchVenueForUser(user_id);
      setVenue(userVenue);

      // Fetch the user's unit settings
      const userUnits = await fetchUnitSettingsForUser(user_id);
      if (userUnits) {
        setUnits(userUnits);
      }

      // Fetch the user's account type
      const type = await getUserAccountType(user_id);
      setAccountType(type);

      setLoading(false);
    };

    fetchData();
  }, [user_id]);

  const handleUnitChange = (e) => {
    const { name, value } = e.target;
    setUnits((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await saveUnitsToBackend(user_id, units);
      alert('Settings updated successfully');
    } catch (error) {
      console.error('Error saving settings:', error);
      alert('Error updating settings');
    }
  };

  if (loading) return <div className="general-settings">Loading...</div>;

  return (
    <div className="general-settings">
      <h2>General Settings</h2>

      <section>
        <h3>Profile Information</h3>
        <p>
          <strong>Account Type:</strong> {accountType}
        </p>
        <p>
          <strong>Venue:</strong> {venue ? venue : 'No venue selected'}
        </p>
      </section>

      <section>
        <h3>Unit Settings</h3>
        <div>
          <label>
            Temperature Units:
            <select
              name="t2m_units"
              value={units.t2m_units}
              onChange={handleUnitChange}
            >
              <option value="F">Fahrenheit</option>
              <option value="C">Celsius</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            Precipitation Units:
            <select
              name="precipitation_units"
              value={units.precipitation_units}
              onChange={handleUnitChange}
            >
              <option value="in">Inches</option>
              <option value="mm">Millimeters</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            Wind Speed Units:
            <select
              name="ws2m_units"
              value={units.ws2m_units}
              onChange={handleUnitChange}
            >
              <option value="mph">MPH</option>
              <option value="kmph">KMPH</option>
            </select>
          </label>
        </div>
        <button onClick={handleSave}>Save Settings</button>
      </section>
    </div>
  );
}

export default GeneralSettings;
