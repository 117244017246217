// SettingsPage.jsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SideMenu from '../components/SideMenu';
import GeneralSettings from '../settings/GeneralSettings';
import PreferencesSettings from '../settings/PreferencesSettings';
import VenueManagement from '../settings/VenueManagement';
import RedoMatching from '../settings/RedoMatching';
import BillingSettings from '../settings/BillingSettings';

import '../styles/SettingsPage.css';

function SettingsPage() {
  const { user_id } = useParams();
  const [accountType, setAccountType] = useState(null); // 'couple' or 'business'
  const [activeTab, setActiveTab] = useState('general');
  const [loading, setLoading] = useState(true);

  // Fetch account type from server
  useEffect(() => {
    async function fetchAccountType() {
      try {
        const response = await fetch(
          `https://junbispark.net/get_user_account_type/?user_id=${user_id}`
        );

        const json = await response.json();

        if (json.status === 'success') {
          setAccountType(json.account_type);
        } 
        
        else {
          console.error('Error fetching account type:', json.message);
        }
      } 
      
      catch (error) {
        console.error('Error fetching account type:', error);
      } 
      
      finally {
        setLoading(false);
      }
    }

    if (user_id) {
      fetchAccountType();
    }
  }, [user_id]);

  if (loading) {
    return <div>Loading settings...</div>;
  }

  if (!accountType) {
    return <div>Error loading account type.</div>;
  }

  return (
    <div className="settings-layout">
      <SideMenu userId={user_id} />
      <div className="settings-content">
        <h1>Settings</h1>
        <div className="settings-tabs">
          <div
            className={`tab ${activeTab === 'general' ? 'active' : ''}`}
            onClick={() => setActiveTab('general')}
          >
            <div className="tab-title">General</div>
            <div className="tab-description">Profile & Account Details</div>
          </div>
          <div
            className={`tab ${activeTab === 'preferences' ? 'active' : ''}`}
            onClick={() => setActiveTab('preferences')}
          >
            <div className="tab-title">Preferences</div>
            <div className="tab-description">Customize your experience</div>
          </div>
          {accountType === 'business' && (
            <div
              className={`tab ${activeTab === 'venue' ? 'active' : ''}`}
              onClick={() => setActiveTab('venue')}
            >
              <div className="tab-title">Manage Venues</div>
              <div className="tab-description">Control your venues</div>
            </div>
          )}
          {accountType === 'couple' && (
            <div
              className={`tab ${activeTab === 'matching' ? 'active' : ''}`}
              onClick={() => setActiveTab('matching')}
            >
              <div className="tab-title">Redo Matching</div>
              <div className="tab-description">Restart your matching process</div>
            </div>
          )}
          <div
            className={`tab ${activeTab === 'billing' ? 'active' : ''}`}
            onClick={() => setActiveTab('billing')}
          >
            <div className="tab-title">Billing</div>
            <div className="tab-description">Subscription & Payments</div>
          </div>
        </div>
        <div className="settings-tab-content">
          {activeTab === 'general' && <GeneralSettings user_id={user_id} />}
          {activeTab === 'preferences' && <PreferencesSettings user_id={user_id} />}
          {activeTab === 'venue' && accountType === 'business' && (
            <VenueManagement user_id={user_id} />
          )}
          {activeTab === 'matching' && accountType === 'couple' && (
            <RedoMatching user_id={user_id} />
          )}
          {activeTab === 'billing' && <BillingSettings user_id={user_id} />}
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
