import React, { useState, useEffect, useMemo } from 'react';
import WeatherChart from './WeatherChart';
import TabSelector from '../components/TabSelector';
import { CHART_CONFIGS } from '../misc/chartConfigs';
import '../styles/ExpandableCard.css';

const ExpandableCard = ({ venue, forecastData, unitSettings }) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState('temperature'); // default tab
  const [localForecastData, setLocalForecastData] = useState(forecastData);

  // Pick either venue.date or the first forecast date as the default date
  useEffect(() => {
    if (venue.date) {
      console.log("Using venue date:", venue.date);
      setSelectedDate(venue.date);
    } else if (forecastData && forecastData.length > 0) {
      const firstDateObj = new Date(Number(forecastData[0].forecast_date));
      const localDateString = [
        firstDateObj.getFullYear(),
        String(firstDateObj.getMonth() + 1).padStart(2, '0'),
        String(firstDateObj.getDate()).padStart(2, '0'),
      ].join('-');

      setSelectedDate(localDateString);
    }
  }, [venue, forecastData]);

  // Fetch forecast data when selectedDate is set
  const fetchForecastDataForDate = async (dateStr) => {
    try {
      // Construct your API endpoint URL
      const url = `https://your-api-domain.com/forecast?date=${dateStr}&venue_id=${venue.id}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.status}`);
      }
      const json = await response.json();
      // Assume your API returns the forecast data in a field like "data"
      setLocalForecastData(json.data);
    } catch (error) {
      console.error("Failed to fetch forecast data:", error);
    } 
  };

  useEffect(() => {
    if (selectedDate) {
      fetchForecastDataForDate(selectedDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  // Helper to convert a forecastData timestamp to local "YYYY-MM-DD"
  const formatLocalDate = (timestamp) => {
    const date = new Date(Number(timestamp));
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Filter the data based on the selected date
  const filteredData = useMemo(() => {
    if (!selectedDate || !localForecastData) return [];
    return localForecastData.filter((dataPoint) => {
      const localDate = formatLocalDate(dataPoint.forecast_date);
      return localDate === selectedDate;
    });
  }, [selectedDate, localForecastData]);

  // Transform data to match WeatherChart’s structure
  const formattedData = useMemo(() => {
    if (filteredData.length === 0) return [];
    return filteredData.map((dataPoint) => {
      const date = new Date(Number(dataPoint.forecast_date));
      return {
        date: date.getTime(),
        temperature: parseFloat(dataPoint.temperature || 0),
        temperatureMin: parseFloat(dataPoint.temperatureMin || 0),
        temperatureMax: parseFloat(dataPoint.temperatureMax || 0),
        precipitation: parseFloat(dataPoint.precipitation || 0),
        cloud: parseFloat(dataPoint.cloud || 0),
        windSpeed: parseFloat(dataPoint.windSpeed || 0),
        windDirection: parseFloat(dataPoint.windDirection || 0),
        watts: parseFloat(dataPoint.watts || 0),
        wattsMin: parseFloat(dataPoint.wattsMin || 0),
        wattsMax: parseFloat(dataPoint.wattsMax || 0),
        rh2m: parseFloat(dataPoint.rh2m || 0),
        rh2mMin: parseFloat(dataPoint.rh2mMin || 0),
        rh2mMax: parseFloat(dataPoint.rh2mMax || 0),
      };
    });
  }, [filteredData]);

  // Determines the suffix for tooltips depending on the active tab
  const getUnitSuffix = () => {
    if (
      activeTab === 'temperature' ||
      activeTab === 'temperatureMin' ||
      activeTab === 'temperatureMax'
    ) {
      return `°${unitSettings?.t2m_units || 'F'}`;
    }
    if (activeTab === 'windSpeed') {
      return ` ${unitSettings?.WIND_UNIT_KEY || ''}`;
    }
    if (activeTab === 'precipitation') {
      return ` ${unitSettings?.PRECIP_UNIT_KEY || ''}`;
    }
    return '';
  };

  // For the chart’s x-axis domain, we want just the selected date from midnight to 23:59:59
  const [year, month, day] = selectedDate.split('-').map(Number);
  const startDateObj = new Date(year, month - 1, day);
  const endDateObj = new Date(year, month - 1, day);
  endDateObj.setHours(23, 59, 59, 999);

  return (
    <div className="expandable-card">
      <div className="card-header" onClick={() => setExpanded(!expanded)}>
        <div className="header-content">
          <h3>{venue.name}</h3>
          <p>
            {selectedDate
              ? new Date(selectedDate + 'T00:00:00').toLocaleDateString()
              : 'Select a date'}
          </p>
        </div>
        <button
          className="toggle-button"
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
          }}
        >
          {expanded ? 'Collapse' : 'Expand'}
        </button>
      </div>

      {expanded && (
        <div className="card-body">
          <div className="static-date-container">
            <strong>Date: </strong>{selectedDate}
          </div>

          {/* Tab Selector */}
          <TabSelector activeTab={activeTab} onTabChange={setActiveTab} />

          <div className="chart-container">
            {formattedData.length > 0 ? (
              <WeatherChart
                data={formattedData}
                startDate={startDateObj}
                endDate={endDateObj}
                interval={0}
                unitSettings={unitSettings}
                chartConfig={CHART_CONFIGS[activeTab]}
                calculateDomain={(data, minKey, maxKey, margin) => {
                  const minValues = data.map(item => item[minKey]);
                  const maxValues = data.map(item => item[maxKey]);

                  if (minValues.length === 0 || maxValues.length === 0) {
                    return [0, 100]; // Default domain if no data
                  }

                  const minValue = Math.min(...minValues) - margin;
                  const maxValue = Math.max(...maxValues) + margin;
                  return [minValue, maxValue];
                }}
                CustomTooltip={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    const date = new Date(label).toLocaleDateString();
                    const unitSuffix = getUnitSuffix();
                    return (
                      <div className="custom-tooltip">
                        <p>{`Date: ${date}`}</p>
                        <p>{`${activeTab}: ${payload[0].value}${unitSuffix}`}</p>
                        <p>{`Min: ${payload[0].payload[CHART_CONFIGS[activeTab].minKey]}${unitSuffix}`}</p>
                        <p>{`Max: ${payload[0].payload[CHART_CONFIGS[activeTab].maxKey]}${unitSuffix}`}</p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
            ) : (
              <p>No forecast data available for the selected date.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpandableCard;
