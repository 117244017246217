export const fetchVenueForUser = async (userId) => {
    try {
        const url = `https://junbispark.net/get_user_state_assignment/?user_id=${userId}`
        const response = await fetch(url)
        const result = await response.json()

        if (result['status'] === 'success') {
            return result['venue_name']
        }
    } 
    
    catch (error) {
        console.error('Error fetching venue name for user', userId, ':', error);
    }

    return null;
}

export const saveVenueToUser = async (user_id, venueName) => {
    try {
        const response = await fetch(`https://junbispark.net/select_venue/?user_id=${user_id}&venue_name=${venueName}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const result = await response.json()

        if (result['status'] !== 'success') {
            console.log('Saving venue to user profile got non-success response:', result)
        }
    } 
    
    catch (error) {
        console.log('Saving venue to user profile got error:', error)
    }
}

export async function fetchUnitSettingsForUser(userId) {
    try {
      const url = `https://junbispark.net/get_unit_settings/?user_id=${userId}`;
      const response = await fetch(url);
      const result = await response.json();

      if (result.status === 'success') {
        delete result.status;
        return result;
      }
    } 
    
    catch (err) {
      console.error('Error fetching unit settings:', err);
    }
    
    return null;
}

export const getVenuesList = async (userId) => {
    try {
      const response = await fetch(`https://junbispark.net/get_all_venues/?user_id=${userId}`);
      const data = await response.json();

      if (data.status === 'success') {
        return data.venues;
      }
      
      else {
        console.error("Error from server:", data.message);
        return [];
      }
    } 
    
    catch (error) {
      console.error("Error fetching venues list:", error);
      return [];
    }
}

export const getUserAccountType = async (userId) => {
    try {
      const response = await fetch(`https://junbispark.net/get_user_account_type/?user_id=${userId}`);
      const data = await response.json();

      if (data.status === 'success') {
        return data.account_type; // expected to be 'couple' or 'business'
      }
    } 
    
    catch (error) {
      console.error('Error fetching account type:', error);
    }
    return 'business'; // default fallback
}

export const getUserSelectedVenues = async (userId) => {
  try {
    const response = await fetch(`https://junbispark.net/get_user_selected_venues/?user_id=${userId}`);
    const data = await response.json();

    if (data.status === 'success') {
      return data.selected_venues;
    } 
    
    else {
      console.error("Error from server:", data.message);
      return [];
    }
  } 
  
  catch (error) {
    console.error("Error fetching user selected venues:", error);
    return [];
  }
}

export async function saveUnitsToBackend(user_id, units) {
  try {
    const response = await fetch(`https://junbispark.net/save_unit_settings/?user_id=${user_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ units }),
    });

    if (!response.ok) {
      throw new Error(`Failed to save unit settings, status: ${response.status}`);
    }
    
    return await response.json();
  } 
  
  catch (error) {
    console.error(error);
    throw error;
  }
}