import React, { useEffect, useState } from 'react';
import ExpandableCard from '../components/ExpandableCard';
import SideMenu from '../components/SideMenu';
import { useUnitSettings } from '../hooks/useUnitSettings';
import Cookies from 'js-cookies';
import '../styles/CoupleDashboard.css';

// Map the raw server data into the shape expected by our UI.
function mapServerData(rawData) {
  console.log("Mapping raw data:", rawData);
  return rawData.map(item => {
    const timestamp = item['time'];
    const dateObj = new Date(timestamp);
    return {
      date: dateObj,
      forecast_date: dateObj.getTime(),
      precipitation: item['PRECTOTCORR_Average'],
      precipitationMin: item['PRECTOTCORR_Min'],
      precipitationMax: item['PRECTOTCORR_Max'],
      temperature: item['T2M_Average'],
      temperatureMin: item['T2M_Min'],
      temperatureMax: item['T2M_Max'],
      windSpeed: item['WS2M_Average'],
      windSpeedMin: item['WS2M_Min'],
      windSpeedMax: item['WS2M_Max'],
      windDirection: item['WD2M_Average'],
      windDirectionMin: item['WD2M_Min'],
      windDirectionMax: item['WD2M_Max'],
      watts: item['ALLSKY_SFC_SW_DWN_Average'],
      wattsMin: item['ALLSKY_SFC_SW_DWN_Min'],
      wattsMax: item['ALLSKY_SFC_SW_DWN_Max'],
      cloud: item['CLOUD_AMT_Average'],
      cloudMin: item['CLOUD_AMT_Min'],
      cloudMax: item['CLOUD_AMT_Max'],
      rh2m: item['RH2M_Average'],
      rh2mMin: item['RH2M_Min'],
      rh2mMax: item['RH2M_Max'],
    };
  });
}

const CoupleDashboard = ({ user_id }) => {
  const { unitSettings } = useUnitSettings(user_id);
  const [forecastResults, setForecastResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchDashboardForecasts() {
      try {
        setLoading(true);

        const scale = "hourly";

        // Get the session cookie (fallback to 'test' for local testing).
        const session_cookie = Cookies.getItem('session_cookie') || 'test';
    

        // Build the API URL using the new endpoint.
        const url = `https://junbispark.net/get_couple_dashboard_data/?user_id=${user_id}&scale=${scale}&session_cookie=${session_cookie}&t2m_units=${unitSettings.t2m_units}&precipitation_units=${encodeURIComponent(unitSettings.precipitation_units)}&ws2m_units=${unitSettings.ws2m_units}`;

        const response = await fetch(url);
        

        if (!response.ok) {
          throw new Error(`Fetch failed with status ${response.status}`);
        }
        
        const json = await response.json();
      
        
        if (json.status !== "success") {
          throw new Error(json.message || "Error fetching dashboard data");
        }

        // Process results: each object has a venue and its matches.
        const results = json.results.map((result, index) => {
          console.log(`Processing result ${index}:`, result);
          return {
            venue: result.venue,
            matches: mapServerData(result.matches)
          };
        });

        setForecastResults(results);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    }

    if (user_id) {
      fetchDashboardForecasts();
    } else {
      setLoading(false);
      setError("User ID not provided");
      console.log("User ID not provided.");
    }
  }, [user_id, unitSettings]);

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="spinner"></div>
        <p>Loading forecast data...</p>
      </div>
    );
  }
  
  if (error) {
    return <div className="couple-dashboard-error">Error: {error}</div>;
  }

  if (!forecastResults || forecastResults.length === 0) {
    console.log("No forecastResults available:", forecastResults);
    return <div className="couple-dashboard-no-data">No forecast data available.</div>;
  }

  console.log("Rendering forecastResults:", forecastResults);
  return (
    <div className="couple-dashboard">
      <SideMenu userId={user_id} />
      <div className="dashboard-content">
        <h1>Your Dashboard</h1>
        <div className="expandable-cards-container">
          {forecastResults.map((result, index) => (
            <ExpandableCard
              key={index}
              venue={result.venue || { name: `Venue ${index + 1}` }}
              forecastData={result.matches || []}
              unitSettings={unitSettings}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoupleDashboard;
