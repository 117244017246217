import React, { useEffect, useState } from 'react';
import { FAHRENHEIT, IMPERIAL, METRIC, IMPERIAL_UNITS, METRIC_UNITS } from '../misc/constants';
import { fetchUnitSettingsForUser, saveUnitsToBackend } from '../utils/queries';
import './styles/PreferencesSettings.css';

function PreferencesSettings({ user_id }) {
  const [unitSystem, setUnitSystem] = useState(IMPERIAL);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadUnits() {
      const units = await fetchUnitSettingsForUser(user_id);
      if (units) {
        // Assuming the backend returns a property called 'temperature'
        const isFahrenheit = units.temperature === FAHRENHEIT;
        setUnitSystem(isFahrenheit ? IMPERIAL : METRIC);
      }
      setLoading(false);
    }
    loadUnits();
  }, [user_id]);

  const handleUnitToggle = async () => {
    let newUnits;
    if (unitSystem === IMPERIAL) {
      newUnits = METRIC_UNITS;
      setUnitSystem(METRIC);
    } else {
      newUnits = IMPERIAL_UNITS;
      setUnitSystem(IMPERIAL);
    }
    await saveUnitsToBackend(user_id, newUnits);
  };

  if (loading) {
    return <div className="preferences-settings">Loading preferences...</div>;
  }

  return (
    <div className="preferences-settings">
      <h2>Preferences</h2>
      <p>
        Current measurement system: <strong>{unitSystem === IMPERIAL ? 'Imperial (F)' : 'Metric (C)'}</strong>
      </p>
      <button onClick={handleUnitToggle}>
        {unitSystem === IMPERIAL ? 'Switch to Metric' : 'Switch to Imperial'}
      </button>
      {/* Additional preferences can be added here */}
    </div>
  );
}

export default PreferencesSettings;
