import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { getVenuesList, saveVenueToUser } from '../utils/queries';
import './styles/VenueManagement.css';

function VenueManagement({ user_id }) {
  const [venueList, setVenueList] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(null);

  useEffect(() => {
    async function fetchVenues() {
      // getVenuesList is expected to return an array of venue names.
      const venues = await getVenuesList(user_id);
      if (venues && venues.length > 0) {
        const options = venues.map((v) => ({ value: v, label: v }));
        setVenueList(options);
      }
    }
    fetchVenues();
  }, [user_id]);

  const handleVenueSelect = async (venue) => {
    setSelectedVenue(venue);
    await saveVenueToUser(user_id, venue.value);
  };

  return (
    <div className="venue-management">
      <h2>Manage Venues</h2>
      <Select
        value={selectedVenue}
        options={venueList}
        onChange={handleVenueSelect}
        isSearchable={true}
        placeholder="Select or type to search..."
        noOptionsMessage={() => "No venues found"}
      />
    </div>
  );
}

export default VenueManagement;
