import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import VenueCard from '../components/VenueCard';

import '../styles/Preferences.css';

const Preferences = () => {
  const { user_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const forecastRef = useRef(null);

  // Retrieve the venues selected in the Distance page (for couples, this might be an array of venue names)
  const selectedVenues = location.state?.selectedVenues || [];

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];
  // Default end date: one week from today
  const defaultEndDate = new Date();
  defaultEndDate.setDate(defaultEndDate.getDate() + 7);
  const defaultEndDateStr = defaultEndDate.toISOString().split('T')[0];

  // State for the weather preferences (with added date range)
  const [preferences, setPreferences] = useState({
    temperature: '',      // Options: 'cool', 'warm', 'hot'
    humidity: '',         // Options: 'humid', 'dry'
    sunClouds: '',        // Options: 'clear', 'romanticClouds'
    sunset: '',           // Options: '6:00 PM', '6:30 PM', '7:00 PM', '7:30 PM', '8:00 PM'
    wind: '',             // Options: 'calm', 'breeze'
    precipitation: '',    // Options: 'yes', 'no'
    startDate: today,     // Start date (default: today)
    endDate: defaultEndDateStr, // End date (default: one week from today)
  });

  const [isLoading, setIsLoading] = useState(false);
  const [forecastResults, setForecastResults] = useState(null);
  const [apiError, setApiError] = useState(null);

  // New state: store each venue's selected forecast match (which includes forecast_date)
  // keyed by venue name.
  const [selectedMatches, setSelectedMatches] = useState({});

  const handlePreferenceChange = (key, value) => {
    setPreferences(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSeeForecast = async () => {
    setIsLoading(true);
    setApiError(null);

    // Dummy values for demonstration.
    const user_id = "test_user";
    const start_time = preferences.startDate ? `${preferences.startDate} 00:00:00` : "2025-03-01 00:00:00";
    const end_time = preferences.endDate ? `${preferences.endDate} 23:59:59` : "2025-03-07 23:59:59";
    const session_cookie = "test";

    const baseUrl = "https://junbispark.net/get_multiple_forecast_matches/";
    const params = new URLSearchParams();
    params.append("user_id", user_id);
    params.append("start_time", start_time);
    params.append("end_time", end_time);
    params.append("temperature", preferences.temperature);
    params.append("humidity", preferences.humidity);
    params.append("sunClouds", preferences.sunClouds);
    params.append("wind", preferences.wind);
    params.append("precipitation", preferences.precipitation);
    params.append("sunset_param", preferences.sunset);
    params.append("session_cookie", session_cookie);
    // Append each selected venue (assumed to be a string)
    selectedVenues.forEach(venue => params.append("venues", venue));
    params.append("scale", "daily");

    try {
      const response = await fetch(`${baseUrl}?${params.toString()}`);
      const data = await response.json();
      console.log("API response data:", data);
      if (data.status === 'success') {
        setForecastResults(data.results);
      } else {
        setApiError(data.message || "Error fetching forecast");
      }
    } catch (error) {
      console.error("Error fetching forecast:", error);
      setApiError("Error fetching forecast. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (forecastResults && forecastRef.current) {
      setTimeout(() => {
        forecastRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
  }, [forecastResults]);

  // When ready to move to the next page (dashboard), store the selections in Firestore and navigate.
  const handleNext = async () => {
    // Build an array of selections from selectedMatches.
    // Each entry: { name: <venue name>, date: <YYYY-MM-DD> }
    const selections = Object.entries(selectedMatches).map(([venueName, match]) => ({
      name: venueName,
      // Convert forecast_date (assumed to be a timestamp string) into YYYY-MM-DD.
      date: new Date(Number(match.forecast_date)).toISOString().split('T')[0]
    }));
  
    try {
      // First, store the user’s venue/date selections.
      const storeResponse = await fetch('https://junbispark.net/store_user_preferences/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_id, // using user_id from useParams (make sure not to override it with any test value)
          selected_venues: selections
        })
      });
      
      const storeJson = await storeResponse.json();
      if (storeJson.status === 'success') {
        // Next, check the account status to determine if the user is on the essentials plan.
        const statusResponse = await fetch(`https://junbispark.net/account_status/?user_id=${user_id}`, {
          method: 'GET',
          credentials: 'include'
        });

        const statusData = await statusResponse.json();

        if (statusData.status === 'success') {
          const subscriptionPlan = statusData.account_status.subscription?.plan;
          // If the subscription is "essentials", call the endpoint to use the credit.
          if (subscriptionPlan === 'essentials') {
            const useCreditResponse = await fetch('https://junbispark.net/use_credit/', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ user_id })
            });
            const creditData = await useCreditResponse.json();

            if (creditData.status !== 'success') {
              console.error("Failed to use credit:", creditData.message);
            } 
            
            else {
              console.log("Credit used successfully. Credits remaining:", creditData.credits_remaining);
            }
          }
        } 
        
        else {
          console.error("Error fetching account status:", statusData.message);
        }
        // Finally, navigate to the dashboard.
        navigate(`/dashboard/${user_id}`, {
          state: {
            selectedMatches,
            forecastResults,
            preferences,
          },
        });
      } 
      
      else {
        console.error('Error saving selections:', storeJson.message);
      }
    } 
    
    catch (error) {
      console.error('Error saving selections:', error);
    }
  };

  return (
    <div className='preferences-page-main-container'>
      <header className="logo">
          <a href="/">BestDay2Marry</a>
        </header>
      <div className="preferences-page-container">
        
        <h2 className="page-title">What is Your Perfect Weather?</h2>
        <p className="page-description">
          Welcome! This form is designed to help you find the best wedding dates 
          based on your chosen venue(s) and your ideal weather conditions. 
          Customize your preferences for temperature, humidity, sky conditions, 
          sunset times, wind, and even a touch of romantic rain. Our system will match 
          your settings with available forecast data and score potential dates within your 
          selected range, so you can make the most informed choice for your special day.
        </p>

        <form className="preferences-form">
          {/* Temperature Preference */}
          <fieldset className="preference-group">
            <legend>Temperature</legend>
            <label>
              <input
                type="radio"
                name="temperature"
                value="cool"
                checked={preferences.temperature === 'cool'}
                onChange={(e) => handlePreferenceChange('temperature', e.target.value)}
              />
              Cool (65-69°F)
            </label>
            <label>
              <input
                type="radio"
                name="temperature"
                value="warm"
                checked={preferences.temperature === 'warm'}
                onChange={(e) => handlePreferenceChange('temperature', e.target.value)}
              />
              Warm (70-75°F)
            </label>
            <label>
              <input
                type="radio"
                name="temperature"
                value="hot"
                checked={preferences.temperature === 'hot'}
                onChange={(e) => handlePreferenceChange('temperature', e.target.value)}
              />
              Hot (76-85°F)
            </label>
          </fieldset>

          {/* Humidity Preference */}
          <fieldset className="preference-group">
            <legend>Humidity</legend>
            <label>
              <input
                type="radio"
                name="humidity"
                value="humid"
                checked={preferences.humidity === 'humid'}
                onChange={(e) => handlePreferenceChange('humidity', e.target.value)}
              />
              Humid
            </label>
            <label>
              <input
                type="radio"
                name="humidity"
                value="dry"
                checked={preferences.humidity === 'dry'}
                onChange={(e) => handlePreferenceChange('humidity', e.target.value)}
              />
              Dry
            </label>
          </fieldset>

          {/* Sun/Clouds Preference */}
          <fieldset className="preference-group">
            <legend>Sun/Clouds</legend>
            <label>
              <input
                type="radio"
                name="sunClouds"
                value="clear"
                checked={preferences.sunClouds === 'clear'}
                onChange={(e) => handlePreferenceChange('sunClouds', e.target.value)}
              />
              Clear Skies <small>(bright and sunny)</small>
            </label>
            <label>
              <input
                type="radio"
                name="sunClouds"
                value="romanticClouds"
                checked={preferences.sunClouds === 'romanticClouds'}
                onChange={(e) => handlePreferenceChange('sunClouds', e.target.value)}
              />
              Romantic Clouds <small>(soft, dreamy overcast adding a touch of drama)</small>
            </label>
          </fieldset>

          {/* Sunset Preference */}
          <fieldset className="preference-group">
            <legend>Sunset (No earlier than...)</legend>
            <select
              value={preferences.sunset}
              onChange={(e) => handlePreferenceChange('sunset', e.target.value)}
            >
              <option value="">Select a time</option>
              <option value="6:00 PM">6:00 PM</option>
              <option value="6:30 PM">6:30 PM</option>
              <option value="7:00 PM">7:00 PM</option>
              <option value="7:30 PM">7:30 PM</option>
              <option value="8:00 PM">8:00 PM</option>
            </select>
          </fieldset>

          {/* Wind Preference */}
          <fieldset className="preference-group">
            <legend>Wind</legend>
            <label>
              <input
                type="radio"
                name="wind"
                value="calm"
                checked={preferences.wind === 'calm'}
                onChange={(e) => handlePreferenceChange('wind', e.target.value)}
              />
              Calm
            </label>
            <label>
              <input
                type="radio"
                name="wind"
                value="breeze"
                checked={preferences.wind === 'breeze'}
                onChange={(e) => handlePreferenceChange('wind', e.target.value)}
              />
              Light Breeze
            </label>
          </fieldset>

          {/* Precipitation Preference */}
          <fieldset className="preference-group">
            <legend>Light Precipitation</legend>
            <p className="helper-text">Would you like a gentle hint of rain to add a romantic ambiance to your wedding day?</p>
            <label>
              <input
                type="radio"
                name="precipitation"
                value="yes"
                checked={preferences.precipitation === 'yes'}
                onChange={(e) => handlePreferenceChange('precipitation', e.target.value)}
              />
              Yes, a light drizzle for romance
            </label>
            <label>
              <input
                type="radio"
                name="precipitation"
                value="no"
                checked={preferences.precipitation === 'no'}
                onChange={(e) => handlePreferenceChange('precipitation', e.target.value)}
              />
              No, I prefer dry conditions
            </label>
          </fieldset>

          {/* Date Range Preference */}
          <fieldset className="preference-group">
            <legend>Date Range</legend>
            <label>
              Start Date:
              <input 
                type="date" 
                name="startDate" 
                value={preferences.startDate} 
                min={today} 
                max="2025-06-01" 
                onChange={(e) => handlePreferenceChange('startDate', e.target.value)}
              />
            </label>
            <label>
              End Date:
              <input 
                type="date" 
                name="endDate" 
                value={preferences.endDate} 
                min={preferences.startDate} 
                max="2025-06-01" 
                onChange={(e) => handlePreferenceChange('endDate', e.target.value)}
              />
            </label>
          </fieldset>
        </form>

        <div className="next-step-container">
          <button className="next-step-button" onClick={handleSeeForecast}>
            See Dates
          </button>
        </div>

        {isLoading && (
          <div className="loading-container">
            <div className="spinner"></div>
            <p>Loading forecast...</p>
          </div>
        )}

        {apiError && <p className="error">{apiError}</p>}

        {forecastResults && (
          <div ref={forecastRef}>
            <div className="venues-bucket">
              {forecastResults.map((result, index) => {
                console.log("Rendering VenueCard for:", result.venue.name);
                return (
                  <VenueCard 
                    key={index} 
                    venue={result.venue} 
                    matches={result.matches} 
                    // onSelectDate will update selectedMatches for this venue.
                    onSelectDate={(selectedMatch) => {
                      setSelectedMatches(prev => ({ ...prev, [result.venue.name]: selectedMatch }));
                    }}
                  />
                );
              })}
            </div>
            <div className="next-step-container">
              <button className="next-step-button" onClick={handleNext}>
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Preferences;
