import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { venueDetailsMapping } from '../misc/venueDetailsMapping';

import DangerIcon from '../assets/modal-icon-danger.png';

import '../styles/TagsMatching.css';

const TagsMatching = () => {
  const navigate = useNavigate();
  const { user_id } = useParams();

  // Options for venue types
  const options = [
    { value: 'Garden', label: 'Garden' },
    { value: 'Mountain', label: 'Mountain View' },
    { value: 'Ocean', label: 'Ocean View' },
    { value: 'Desert', label: 'Desert View' },
    { value: 'Winery', label: 'Winery' },
    { value: 'National Park', label: 'National Park' },
    { value: 'Ranch', label: 'Ranch' },
    { value: 'Golf Club', label: 'Golf Club' },
    { value: 'Luxury Hotel', label: 'Luxury Hotel' },
    { value: 'Landmark Hotel', label: 'Landmark Hotel' },
    { value: 'City Hall', label: 'City Hall' },
  ];

  // State variables for tag selection and venue data
  const [selectedTags, setSelectedTags] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [venuesData, setVenuesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [selectedVenues, setSelectedVenues] = useState([]);

  // Account status state
  const [accountStatus, setAccountStatus] = useState(null);
  // For modal alerts when selection exceeds the allowed limit
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  // Toggle the dropdown for tag selection
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  // Handle clicks on tag options
  const handleOptionClick = (option) => {
    if (selectedTags.includes(option.value)) {
      setSelectedTags(selectedTags.filter((tag) => tag !== option.value));
    } else {
      setSelectedTags([...selectedTags, option.value]);
    }
  };

  const renderSelectedTags = () => {
    const labels = selectedTags
      .map((tagValue) => {
        const option = options.find((opt) => opt.value === tagValue);
        return option ? option.label : tagValue;
      })
      .join(', ');
    return labels || 'Select venue types...';
  };

  // Fetch account status from your API once the component mounts.
  useEffect(() => {
    const url = `https://junbispark.net/account_status/?user_id=${user_id}`;
    console.log("Fetching account status from URL:", url);

    fetch(url, { credentials: 'include' })
      .then((response) => {
        console.log("Response received:", response);
        return response.json();
      })
      .then((data) => {
        console.log("Data received:", data);
        if (data.status === 'success') {
          setAccountStatus(data.account_status);
          console.log("Account status set:", data.account_status);
        } else {
          console.error("Error fetching account status:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
      });
  }, [user_id]);

  // Handle fetching venues based on selected tags
  const handleExploreVenues = async () => {
    if (selectedTags.length === 0) {
      alert('Please select at least one tag.');
      return;
    }
    setIsLoading(true);
    setApiError(null);

    // Build query string from selected tags
    const queryParams = selectedTags
      .map((tag) => `tags=${encodeURIComponent(tag)}`)
      .join('&');

    try {
      const response = await fetch(`https://junbispark.net/search_venues_by_tags?${queryParams}`);
      const data = await response.json();
      if (data.status === 'success') {
        setVenuesData(data.venues);
      } else {
        setApiError(data.message || 'Error fetching venues.');
      }
    } catch (error) {
      console.error('Error fetching venues:', error);
      setApiError('Error fetching venues. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Determine max allowed venues based on subscription plan
  const getMaxAllowedVenues = () => {
    if (accountStatus) {
      const plan = accountStatus.subscription.plan;
      if (plan === 'trial' || plan === 'essentials') {
        return 1;
      }
    }
    return Infinity; // For standard or premium
  };

  // Handle venue selection
  const handleVenueSelection = (venueName) => {
    console.log("Attempting to select venue:", venueName);
    // If account status isn't loaded, alert the user
    if (!accountStatus) {
      console.warn("Account status not loaded yet");
      alert('Loading account status, please wait.');
      return;
    }

    const maxVenues = getMaxAllowedVenues();
    console.log("Max allowed venues:", maxVenues, "Currently selected:", selectedVenues);

    if (!selectedVenues.includes(venueName)) {
      if (selectedVenues.length >= maxVenues) {
        console.warn("Selection limit reached. Cannot select more venues.");
        setModalMessage(
          "Due to your current plan, you can only select one venue. Upgrade to view more."
        );
        setShowModal(true);
        return;
      }
      setSelectedVenues([...selectedVenues, venueName]);
      console.log("Venue selected. New selectedVenues:", [...selectedVenues, venueName]);
    } else {
      // Allow deselection of a venue
      const newSelection = selectedVenues.filter((name) => name !== venueName);
      setSelectedVenues(newSelection);
      console.log("Venue deselected. New selectedVenues:", newSelection);
    }
  };

  // Proceed to next step if at least one venue is selected
  const handleProceedToForecast = () => {
    if (selectedVenues.length === 0) {
      alert('Please select at least one venue to proceed.');
      return;
    }
    navigate(`/distance/${user_id}`, { state: { selectedVenues } });
  };

  return (
    <div className="test">
      <div className="tags-matching-page-container">
        <header className="logo">
          <a href="/">BestDay2Marry</a>
        </header>
        <div className="tags-matching-container">
          <div className="tags-matching-header">
            <h1>Choose Your Dream Venue for Wedding</h1>
            <p>
              Below is a list of venue types our product offers. Please choose the tags you would enjoy the most.
            </p>
          </div>
          <div className="custom-tags-selector">
            <div className="selected-tags-field" onClick={toggleDropdown}>
              {renderSelectedTags()}
              <span className="dropdown-arrow">&#9662;</span>
            </div>
            {isDropdownOpen && (
              <div className="options-container">
                {options.map((option) => (
                  <div
                    key={option.value}
                    className={`option-item ${selectedTags.includes(option.value) ? 'option-selected' : ''}`}
                    onClick={() => handleOptionClick(option)}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            )}
          </div>
          <button type="button" className="continue-button" onClick={handleExploreVenues}>
            Explore Venues
          </button>
        </div>
        {isLoading && <p>Loading venues...</p>}
        {apiError && <p className="error">{apiError}</p>}
        {venuesData.length > 0 && (
          <div className="venues-bucket">
            {venuesData.map((venue, index) => {
              const details = venueDetailsMapping[venue.name];
              if (!details) return null;
              return (
                <div
                  key={index}
                  className={`venue-card ${selectedVenues.includes(venue.name) ? 'selected' : ''}`}
                  onClick={() => handleVenueSelection(venue.name)}
                >
                  <img src={details.imageUrl} alt={details.title} className="venue-image" />
                  <div className="card-content">
                    <h3 className="venue-title">{details.title}</h3>
                    <p className="venue-caption">{details.caption}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      
      <div className="footer-spacer"></div>

      <div className="sticky-footer">
        <button 
          type="button" 
          className="proceed-button" 
          onClick={handleProceedToForecast}
          disabled={!accountStatus}
        >
          Next Steps
        </button>
      </div>

      {/* Modal for permission errors */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            {/* Danger icon (use your own URL or local asset) */}
            <img 
              src={DangerIcon}
              alt="Danger Icon" 
              className="danger-icon"
            />
            <p>{modalMessage}</p>
            <button onClick={() => setShowModal(false)} className="modal-button">
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TagsMatching;
