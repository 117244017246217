import React, { useState, useEffect } from 'react';
import { venueDetailsMapping } from '../misc/venueDetailsMapping';

const VenueCard = ({ venue, matches, onSelectDate }) => {
  console.log("VenueCard rendered with matches:", matches);
  // Use the mapping if available, otherwise fallback to the venue object
  const details = venueDetailsMapping[venue.name] || {};
  const imageUrl = details.imageUrl || venue.imageUrl || 'https://via.placeholder.com/400x200?text=No+Image';
  const venueTitle = details.title || venue.name;

  // Local state to track the selected match index
  const [selectedMatchIndex, setSelectedMatchIndex] = useState(0);
  const selectedMatch = matches[selectedMatchIndex];

  // When the selected match changes, inform the parent component
  useEffect(() => {
    if (onSelectDate) {
      onSelectDate(selectedMatch);
    }
  }, [selectedMatch, onSelectDate]);

  // Convert the timestamp to a readable date
  const readableDate = new Date(Number(selectedMatch.forecast_date)).toLocaleDateString();

  // Helper to get a color for the match description based on overall_score
  const getMatchColor = (score) => {
    if (score >= 80) return '#28a745'; // green for great match
    else if (score >= 60) return '#ffc107'; // yellow for weak match
    else return '#dc3545'; // red for poor match
  };

  const matchColor = getMatchColor(selectedMatch.overall_score);

  return (
    <div className="venue-card">
      <img src={imageUrl} alt={venueTitle} className="venue-image" />
      <div className="card-content">
        <h3 className="venue-title">{venueTitle}</h3>
        <div className="match-info">
          <p className="match-date">{readableDate}</p>
          <p className="match-description" style={{ color: matchColor }}>
            {selectedMatch.match_description} – {selectedMatch.overall_score}%
          </p>
        </div>
        {/* Render scrollable date tabs if there are multiple matches */}
        {Array.isArray(matches) && matches.length > 1 && (
          <div className="match-dates-container">
            {matches.map((match, idx) => {
              const dateLabel = new Date(Number(match.forecast_date)).toLocaleDateString();
              return (
                <button 
                  key={idx}
                  className={`match-tab ${idx === selectedMatchIndex ? 'active' : ''}`}
                  onClick={() => setSelectedMatchIndex(idx)}
                >
                  {dateLabel}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default VenueCard;
