import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/RedoMatching.css';

function RedoMatching({ user_id }) {
  const navigate = useNavigate();

  const handleRedo = async () => {
    try {
      // Fetch the account status from the backend
      const response = await fetch(`https://junbispark.net/account_status/?user_id=${user_id}`, {
        method: 'GET',
        credentials: 'include'
      });
      const data = await response.json();
      if (data.status === 'success') {
        const accountStatus = data.account_status;
        const subscriptionPlan = accountStatus.subscription?.plan;
        // If the user is on the essentials plan, check the available credit.
        if (subscriptionPlan === 'essentials') {
          const creditsRemaining = accountStatus.subscription.credits_remaining || 0;
          if (creditsRemaining > 0) {
            navigate(`/tags_matching/${user_id}`);
          } else {
            // No credit available: redirect to the matching_flow.
            navigate(`/matching_flow/${user_id}`);
          }
        } else {
          // For non-essentials users, proceed to tags matching.
          navigate(`/tags_matching/${user_id}`);
        }
      } else {
        // In case of error fetching account status, fallback to matching_flow.
        navigate(`/matching_flow/${user_id}`);
      }
    } catch (error) {
      console.error("Error checking account status:", error);
      navigate(`/matching_flow/${user_id}`);
    }
  };

  return (
    <div className="redo-matching">
      <h2>Redo Matching</h2>
      <p>
        If you’d like to restart your preference matching process, click the button below.
      </p>
      <button className="redo-button" onClick={handleRedo}>
        Start Matching
      </button>
    </div>
  );
}

export default RedoMatching;