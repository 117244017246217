import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Label } from 'recharts';

function WeatherChart ({
  data,
  startDate,
  endDate,
  interval,
  unitSettings,
  calculateDomain,
  chartConfig = {},
  CustomTooltip
}) {
  if (!data || data.length === 0) {
    return <p>No data available for this chart.</p>;
  }

  const { 
    dataKey = '', 
    minKey = '', 
    maxKey = '', 
    color = '#8884d8', 
    fillId = 'defaultFill', 
    domainMargin = 5, 
    yAxisLabel = '' 
  } = chartConfig;

  if (!dataKey || !minKey || !maxKey) {
    return <p>Invalid chart configuration.</p>;
  }

  const domain = calculateDomain(data, minKey, maxKey, domainMargin);

  // Determine if we are showing an hourly forecast (less than one day)
  const ONE_DAY = 24 * 60 * 60 * 1000;
  const forecastIsHourly = (new Date(endDate) - new Date(startDate)) <= ONE_DAY;

  // Format X-axis ticks: hourly => 0-23h, daily/weekly => date format
  const formatDateTick = (value) => {
    const timestamp = isNaN(value) ? Date.parse(value) : Number(value);
    const date = new Date(timestamp);
    if (forecastIsHourly) {
      // If the tick is at 0h but not on the same day as startDate, omit it.
      if (date.getHours() === 0 && date.getDate() !== new Date(startDate).getDate()) {
        return '';
      }
      return `${date.getHours()}h`;
    } else {
      return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
    }
  };

  // Format Y-axis ticks: always round to 2 decimals.
  // For solar radiation in daily forecasts, apply a conversion factor (example: divide by 1000)
  const yAxisTickFormatter = (value) => {
    let adjustedValue = value;
    if (dataKey === 'solarRadiation' && !forecastIsHourly) {
      adjustedValue = value / 1000; // adjust this conversion factor as needed
    }
    return Number(adjustedValue).toFixed(2);
  };

  return (
    <ResponsiveContainer width="100%" height={800}>
      <AreaChart data={data} margin={{ top: 16, right: 32, left: 8, bottom: 16 }}>
        <defs>
          <linearGradient id={fillId} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={1} />
            <stop offset="95%" stopColor={color} stopOpacity={0} />
          </linearGradient>
        </defs>

        <XAxis
          dataKey="date"
          axisLine={false}
          interval={interval}
          tickCount={8}
          tickFormatter={formatDateTick}
        />

        <YAxis
          yAxisId="left"
          domain={domain} 
          axisLine={false}
          tickFormatter={yAxisTickFormatter}
        >
          <Label
            value={
              typeof yAxisLabel === 'function'
                ? yAxisLabel(unitSettings)
                : yAxisLabel
            }
            angle={-90}
            position="insideLeft"
            style={{ textAnchor: 'middle' }}
          />
        </YAxis>

        <Tooltip content={<CustomTooltip minKey={minKey} maxKey={maxKey} />} />

        <Area
          yAxisId="left"
          type="monotone"
          dataKey={dataKey}
          stroke={color}
          fillOpacity={1}
          fill={`url(#${fillId})`}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default WeatherChart;
