import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ExpandedTracker from './ExpandedTracker';
import CoupleDashboard from './CoupleDashboard';
import { getUserAccountType, fetchVenueForUser, getUserSelectedVenues } from '../utils/queries';

const Dashboard = () => {
  const { user_id } = useParams();
  const navigate = useNavigate();
  const [userType, setUserType] = useState(null);
  const [venues, setVenues] = useState([]);
  const [accountStatus, setAccountStatus] = useState(null); // holds our account status from backend
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchAccountData() {
      try {
        // Retrieve account type from Firestore.
        const accountType = await getUserAccountType(user_id);
        setUserType(accountType);
        
        if (accountType === 'business') {
          const singleVenue = await fetchVenueForUser(user_id);
          const venueObj = typeof singleVenue === 'string'
            ? { id: singleVenue, name: singleVenue }
            : singleVenue;
          setVenues([venueObj]);
        } else if (accountType === 'couple') {
          const selectedVenues = await getUserSelectedVenues(user_id);
          setVenues(selectedVenues);
        }

        // Fetch account status from backend
        const response = await fetch(`https://junbispark.net/account_status/?user_id=${user_id}`, {
          method: 'GET',
          credentials: 'include'
        });
        const data = await response.json();
        if (data.status === "success") {
          setAccountStatus(data.account_status);
        } else {
          console.error("Error fetching account status", data.message);
        }
      } catch (error) {
        console.error('Error fetching account data:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchAccountData();
  }, [user_id]);

  // Redirect if account is “expired” (trial ended or essentials credit used)
  useEffect(() => {
    if (!loading && accountStatus && accountStatus.expired) {
      const paymentUrl =
        userType === 'business'
          ? `/business_plan/${user_id}`
          : `/mathcing_flow/${user_id}`;
      navigate(paymentUrl);
    }
  }, [loading, accountStatus, userType, navigate, user_id]);

  if (loading) {
    return <div>Loading your dashboard...</div>;
  }

  return (
    <div className="dashboard-page">
      {userType === 'couple' ? (
        <CoupleDashboard venues={venues} user_id={user_id} />
      ) : (
        <ExpandedTracker venue={venues[0]} user_id={user_id} />
      )}
    </div>
  );
};

export default Dashboard;
